/** @jsx jsx */
import { jsx, Flex, Heading } from "theme-ui"
import * as React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../components/layout"
import Seo from "../components/seo"

const PostTemplate = ({ data, location }) => {
  const {
    mdx: post,
    site: {
      siteMetadata: { title },
    },
    previous,
    next,
  } = data

  return (
    <Layout location={location} title={title} wrapper="home">
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="article"
        itemScope
        itemType="http://schema.org/Article"
      >
        <React.Fragment>
          <Heading as="h1" variant="styles.h1">
            {post.frontmatter.title}
          </Heading>
        </React.Fragment>
        <MDXRenderer>{post.body}</MDXRenderer>
      </article>
      <Flex sx={{ marginTop: `3rem` }}>
        {previous && (
          <Link
            to={previous.fields.slug}
            rel="prev"
            key={previous.fields.slug}
            sx={{ marginRight: "auto", color: `primary` }}
          >
            ← {previous.frontmatter.title}
          </Link>
        )}
        {next && (
          <Link
            to={next.fields.slug}
            rel="next"
            key={next.fields.slug}
            sx={{ marginLeft: "auto", color: `primary` }}
          >
            {next.frontmatter.title} →
          </Link>
        )}
      </Flex>
    </Layout>
  )
}

export default PostTemplate

export const pageQuery = graphql`
  query ($id: String!, $previousPostId: String, $nextPostId: String) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        description
      }
    }
    previous: mdx(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
